import colors from 'constants/colors';

import { StyledSpinner } from './index.css';

type CircularSpinnerPropTypes = {
  size?: number;
  color?: string;
};

export const CircularSpinner = ({
  size = 16,
  color = colors.gray850,
}: CircularSpinnerPropTypes) => {
  return (
    <StyledSpinner>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>CircularSpinner</title>
        <path
          d="M2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2V3.125C10.6924 3.125 12.875 5.30761 12.875 8C12.875 10.6924 10.6924 12.875 8 12.875C5.30761 12.875 3.125 10.6924 3.125 8H2Z"
          fill={color}
          stroke="none"
        />
      </svg>
    </StyledSpinner>
  );
};
