import colors from 'constants/colors';

export type RadarIconProps = {
  size?: number;
  color?: string;
};

export const Radar = ({
  size = 16,
  color = colors.gray850,
}: RadarIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Radar Icon</title>
    <path d="M11.5 3L7.99995 8.31953" stroke={color} strokeLinecap="round" />
    <path
      d="M10.1325 4.61588C9.32538 4.10725 8.36301 3.90386 7.41908 4.04242C6.47516 4.18097 5.61184 4.65234 4.9849 5.37147C4.35797 6.09061 4.00872 7.01014 4.00016 7.96414C3.99161 8.91814 4.32431 9.84379 4.93825 10.574C5.55218 11.3043 6.40691 11.7911 7.3482 11.9465C8.28949 12.102 9.25535 11.9159 10.0715 11.4218C10.8876 10.9278 11.5003 10.1583 11.799 9.25217"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M11.3344 2.92382C10.0924 2.16087 8.61166 1.85579 7.15927 2.06363C5.70688 2.27146 4.37852 2.97851 3.41388 4.05721C2.44924 5.1359 1.91185 6.51519 1.89869 7.9462C1.88552 9.3772 2.39745 10.7657 3.34209 11.8611C4.28673 12.9564 5.60187 13.6866 7.0502 13.9198C8.49854 14.153 9.98467 13.8738 11.2404 13.1327C12.4962 12.3916 13.4389 11.2374 13.8984 9.87823"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M9.04374 6.30794C8.62976 6.05362 8.13618 5.95193 7.65205 6.02121C7.16792 6.09049 6.72513 6.32617 6.40358 6.68574C6.08204 7.0453 5.90291 7.50506 5.89852 7.98207C5.89413 8.45907 6.06477 8.92189 6.37965 9.28702C6.69453 9.65215 7.13292 9.89553 7.61569 9.97326C8.09847 10.051 8.59385 9.95795 9.01244 9.71091C9.43103 9.46388 9.74526 9.07912 9.89844 8.62608"
      stroke={color}
      strokeLinecap="round"
    />
    <circle cx="8" cy="8" r="0.5" fill={color} stroke={color} />
  </svg>
);
